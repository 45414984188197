(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/banner_groups/banner-background/assets/javascripts/init.js') >= 0) return;  svs.modules.push('/components/banner_groups/banner-background/assets/javascripts/init.js');

'use strict';

svs.banner_groups.banner_background.init = options => {
  const $el = options.$el;
  if ($el && $el.length > 0) {
    new svs.banner_common.banner_helpers.Form($el);
  }
};

 })(window);